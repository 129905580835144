<template>
    <main>
        <div v-if="!Object.keys(usuario).length" class="mt-2 mb-2 p-2 align-center">
            <button class="btn btn-primary btn-sm lift lift-sm" @click="llenarDatos();">
                Llenar datos del acudiente <i class="fa fa-plus fa-fw"></i>
            </button>
        </div>        
        <div class="row no-gutters" v-else>
            <div class="col-xl-12">
            <div class="card-header"><i class="fas fa-address-book fa-fw"></i> Datos del acudiente</div>
                <div class="card-body">
                    <h6 >NOMBRE Y APELLIDO: {{usuario.nombre_1}} {{usuario.nombre_2}} {{usuario.apellido_1}} {{usuario.apellido_2}}</h6>
                    <h6 >TIPO DE DOCUMENTO: <span v-if="usuario.tipo_documento">{{usuario.tipo_documento.codigo }}</span></h6>
                    <h6 >IDENTIFICACION: {{usuario.documento}}</h6>
                </div>
            </div>
        </div>  
    </main>
</template>
<script>
export default {
    props:['usuario'],
    methods: {
        llenarDatos(){
            this.$router.push({
                name: 'registro.padre', params: { id:this.usuario.id }
            });
        }
    }
}
</script>

