<template>
    <main>
        <modalInformacionUsuario :usuario="usuario"></modalInformacionUsuario>
        <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
            <div class="container-xl px-4">
                <div class="page-header-content pt-4">
                    <div class="row align-items-center justify-content-between">
                        <div class="col-auto mt-4">
                            <h1 class="page-header-title">
                                <div class="page-header-icon">
                                    <i class="far fa-clipboard"></i>
                                </div>
                                Registro PAI
                            </h1>
                            <div class="page-header-subtitle"></div>
                        </div>
                        <div class="col-12 col-xl-auto mt-4">
                            <div class="input-group input-group-joined border-0" style="width: 16.5rem">
                                <button type="button" class="btn btn-warning btn-sm" @click="retornarPagina();">
                                    <i class="fas fa-arrow-circle-left fa-fw"></i> Regresar a Vista PAI
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <br>
        <div class="container-xl px-4 mt-n15">
            <div class="card ">
                <cardInformacionUsuario :usuario="usuario"></cardInformacionUsuario>
            </div>
            <div class="card mt-2 ">
                <informacionPadre :usuario="padre"></informacionPadre>
            </div>
            <div class="mt-2">
                <vacunasComponent :usuario="usuario" :idUsuario="idUsuario"></vacunasComponent>
            </div>
        </div>
    </main>
</template>
<script>
import usuarioService from '../../services/usuarioService';
import cardInformacionUsuario from "../historias/base/informacionUsuario/cardInformacionUsuario";
import modalInformacionUsuario from "../historias/base/informacionUsuario/modalInformacionUsuario";
import vacunasComponent from "./vacunas";
import informacionPadre from "./informacionPadre"

export default {
    components:{
        cardInformacionUsuario,modalInformacionUsuario,vacunasComponent,informacionPadre
    },
    data (){
        return {
            idUsuario:'',
            idPadre:'',
            usuario:{},
            padre:{},
        }
    },
    methods: {
        async cargarUsuario(id){
            const response= await usuarioService.show(id);
            this.usuario=response.data;
        },
        async cargarPadre(id){
            const response= await usuarioService.show(id);
            this.padre=response.data;
        },
        retornarPagina(){
            this.$router.push({name:'pai.index'});
        }
    },
    async created() {
        this.idUsuario = this.$route.params.idUsuario;
        await this.cargarUsuario(this.idUsuario);
        this.idPadre=this.usuario.id_padre;
        await this.cargarPadre(this.idPadre);
    },
}
</script>